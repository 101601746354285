import axios from 'axios';
import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import config from '../../../configs/config';

const apiEndPoint = config.apiEndPoint;

const AddSchedule = () => {
    // const navigate = useNavigate();
    const [pengirim, setPengirim] = useState("");
    const [no, setNo] = useState("");
    const [message, setMessage] = useState("");
    const [text, setText] = useState("");
    const [isSending, setIsSending] = useState(false); // state to track sending status

    useEffect(() => {
        if (pengirim === "") {
            setText(`📬 *Pesan Dari:* _Orang Tidak Dikenal_ \n\n> ${message} \n\nhttps://app.keidev.my.id/send`);
        } else {
            setText(`📬 *Pesan Dari:* _${pengirim}_ \n\n> ${message} \n\nhttps://app.keidev.my.id/send`);
        }
    }, [pengirim, message]);
    
    const sendMessage = async (e) => {
        e.preventDefault();
        setIsSending(true); // Disable the button when sending starts
        try {
            await axios.post(apiEndPoint + '/whatsapp/sendmessage', {
                no,
                text
            });
            setPengirim("");
            setNo("");
            setMessage("");
        } catch (error) {
            console.log(error);
        } finally {
            setIsSending(false); // Re-enable the button after sending
        }
    };

    return (
        <div className='columns mt-5 is-centered'>
            <div className='column is-half'>
                <form onSubmit={sendMessage}>
                    <div className='field'>
                        <label className='label'>Pengirim (Opsional)</label>
                        <div className='control'>
                            <input 
                                type="text" 
                                className='input'
                                value={pengirim} 
                                onChange={(e)=> setPengirim(e.target.value)} 
                                placeholder='Pengirim' 
                            />
                        </div>
                    </div>
                    <div className='field'>
                        <label className='label'>No Whatsapp (Target)</label>
                        <div className='control'>
                            <input 
                                type="number" 
                                className='input' 
                                required 
                                value={no} 
                                onChange={(e)=> setNo(e.target.value)} 
                                placeholder='628XXXXXXXXXX' 
                            />
                        </div>
                    </div>
                    <div className='field'>
                        <label className='label'>Pesan</label>
                        <div className='control'>
                            <textarea
                                className='textarea'
                                required
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                placeholder='Message'
                            />
                        </div>
                    </div>
                    <div className='field'>
                        <button 
                            type="submit" 
                            className='button is-success' 
                            disabled={isSending} // Disable button when sending
                        >
                            {isSending ? "Sending..." : "Send"} {/* Show sending text */}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddSchedule;
