import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from '../../../configs/config';

const ScheduleList = () => {
  const [schedules, setSchedule] = useState([]);

  useEffect(() => {
    getSchedules();
  }, []);

  const getSchedules = async () => {
    const response = await axios.get(config.apiEndPoint + '/whatsapp/schedule');
    setSchedule(response.data.data);
  };

  const deleteSchedule = async (id) => {
    try {
      await axios.delete(config.apiEndPoint + `/whatsapp/schedule/${id}`);
      getSchedules();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container">
      <div className="columns ">
        <div className="column is-8">
          <div className="box">
            <h1 className="title">Schedules</h1>
            <Link to={'addschedule'} className='button is-success'>Add Schedule</Link>
            <table className='table is-striped is-centered'>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Time</th>
                  <th>Date</th>
                  <th>No</th>
                  <th>Message</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {schedules.map((schedule, index) => {
                  const time = `${schedule.hour}.${schedule.minute}.${schedule.second}`;
                  const date = `${schedule.date}.${schedule.month}.${schedule.year}`;
                  return (
                    <tr key={schedule.id}>
                      <td>{index + 1}</td>
                      <td>{schedule.id}</td>
                      <td>{schedule.name}</td>
                      <td>{time}</td>
                      <td>{date}</td>
                      <td>{schedule.no}</td>
                      <td>{schedule.message}</td>
                      <td>
                        <Link to={`editschedule/${schedule.id}`} className='button is-small is-info'>Edit</Link>
                        <button onClick={() => deleteSchedule(schedule.id)} className='button is-small is-danger'>Delete</button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleList;