import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../../configs/config';

const apiEndPoint = config.apiEndPoint;

const AddSchedule = () => {
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [hour, setHour] = useState("");
    const [minute, setMinute] = useState("");
    const [second, setSecond] = useState(""); // Default to empty, will be set by handleDateTimeChange
    const [date, setDate] = useState("");
    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");
    const [no, setNo] = useState("");
    const [message, setMessage] = useState("");
    const [isSending, setIsSending] = useState(false); // State to track if sending

    const handleDateTimeChange = (e) => {
        const datetimeValue = e.target.value;
        const datetime = new Date(datetimeValue);

        setHour(datetime.getHours().toString());
        setMinute(datetime.getMinutes().toString());
        setSecond(datetime.getSeconds().toString()); // Set seconds even though it will likely be 0
        setDate(datetime.getDate().toString());
        setMonth((datetime.getMonth() + 1).toString()); // Months are zero-indexed, so add 1
        setYear(datetime.getFullYear().toString());
    };

    const createSchedule = async (e) => {
        e.preventDefault();
        setIsSending(true); // Disable button when sending starts
        try {
            await axios.post(apiEndPoint + '/whatsapp/schedule', {
                name,
                hour,
                minute,
                second,
                date,
                month,
                year,
                no,
                message
            });
            navigate('/schedulewa');
        } catch (error) {
            console.log(error);
        } finally {
            setIsSending(false); // Re-enable button after sending
        }
    };

    return (
        <div className='columns mt-5 is-centered'>
            <div className='column is-half'>
                <form onSubmit={createSchedule}>
                    <div className='field'>
                        <label className='label'>Name (Bebas)</label>
                        <div className='control'>
                            <input 
                                type="text" 
                                className='input' 
                                required 
                                value={name} 
                                onChange={(e)=> setName(e.target.value)} 
                                placeholder='Name' 
                            />
                        </div>
                    </div>
                    <div className='field'>
                        <label className='label'>No Whatsapp</label>
                        <div className='control'>
                            <input 
                                type="number" 
                                className='input' 
                                required 
                                value={no} 
                                onChange={(e)=> setNo(e.target.value)} 
                                placeholder='628XXXXXXXXXX' 
                            />
                        </div>
                    </div>
                    <div className='field'>
                        <label className='label'>Message</label>
                        <div className='control'>
                            <textarea
                                className='textarea'
                                required
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                placeholder='Message'
                            />
                        </div>
                    </div>
                    <div className='field'>
                        <label className='label'>Date and Time</label>
                        <div className='control'>
                            <input 
                                type="datetime-local" 
                                className='input' 
                                required 
                                onChange={handleDateTimeChange} 
                                placeholder="Time" 
                            />
                        </div>
                    </div>
                    <div className='field'>
                        <button 
                            type="submit" 
                            className='button is-success' 
                            disabled={isSending} // Disable button when sending
                        >
                            {isSending ? "Creating..." : "Create"} {/* Show creating text */}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddSchedule;
